import React, { useState, useEffect, useContext } from 'react'
import MainLayout from '../../components/MainLayout'
import { useLocation } from 'react-router-dom'
import FilteredUnitsSection from './containers/FilteredUnitsSection';
import AllUnitsSection from './containers/AllUnitsSection';
import { Select } from '@headlessui/react'
import { AppContext } from '../../AppContext';
import SEO from '../../components/SEO';

const UnitsPage = ({ forRent }) => {

  const location = useLocation();
  const [locationFilter, setLocationFilter] = useState(location.state?.location || 'Any');
  const [zoneFilter, setZoneFilter] = useState('Any');
  const [bedroomsFilter, setBedroomsFilter] = useState(location.state?.bedrooms || 'Any');
  const [budgetFilter, setBudgetFilter] = useState(location.state?.budget || '');
  const [downPaymentFilter, setDownPaymentFilter] = useState(location.state?.downPayment || '');
  const [installmentsYearsFilter, setInstallmentsYearsFilter] = useState(location.state?.installmentsYears || '');
  const [quarterInstallmentFilter, setQuarterInstallmentFilter] = useState(location.state?.quarterInstallment || '');
  const [propertyTypeFilter, setPropertyTypeFilter] = useState(location.state?.propertyType || 'Any');
  const [forRentFilter, setForRentFilter] = useState(location.state?.forRent || forRent || false);
  const searchingInput = location.state?.searchingInput || 'Any';

  const { units, isSelectedLanguageEnglish, getZone, getArabicZone } = useContext(AppContext);
  const [filteredUnits, setFilteredUnits] = useState([...units]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isCash, setIsCash] = useState(location.state?.isCash || '');

  useEffect(() => {
    let updatedUnits = [...units];
    let anyFiltersApplied = false;

    if (locationFilter !== 'Any' && locationFilter !== 'الكل') {
      updatedUnits = updatedUnits.filter((unit) => unit.location === locationFilter || unit.arabicLocation === locationFilter);
      anyFiltersApplied = true;
    }
    if (zoneFilter !== 'Any') {
      updatedUnits = updatedUnits.filter((unit) => unit.zone === zoneFilter || unit.arabicZone === zoneFilter);
      anyFiltersApplied = true;
    }
    if (bedroomsFilter !== 'Any') {
      if (bedroomsFilter === '5+') {
        updatedUnits = updatedUnits.filter((unit) => unit.bedrooms >= 5);
      } else {
        updatedUnits = updatedUnits.filter((unit) => unit.bedrooms === parseInt(bedroomsFilter));
      }
      anyFiltersApplied = true;
    }
    if (propertyTypeFilter !== 'Any') {
      updatedUnits = updatedUnits.filter((unit) => unit.propertyType === propertyTypeFilter);
      anyFiltersApplied = true;
    }
    if (budgetFilter !== '') {
      setIsCash('true');
      let topPrice = parseInt(budgetFilter.replace(/,/g, ''), 10) * 1.15;
      let basePrice = parseInt(budgetFilter.replace(/,/g, ''), 10) * 0.85;
      updatedUnits = updatedUnits.filter((unit) => {
        let price = parseInt(unit.price.replace(/,/g, ''), 10);
        return price >= basePrice && price <= topPrice
      });
      anyFiltersApplied = true;
    }
    if (downPaymentFilter !== '') {
      setIsCash('false');
      updatedUnits = updatedUnits.filter((unit) => unit.downPayment && parseInt(unit.downPayment.replace(/,/g, ''), 10) <= downPaymentFilter);
      anyFiltersApplied = true;
    }
    if (installmentsYearsFilter !== '') {
      setIsCash('false');
      updatedUnits = updatedUnits.filter((unit) => unit.installmentsYears && parseInt(unit.installmentsYears) >= installmentsYearsFilter);
      anyFiltersApplied = true;
    }
    if (quarterInstallmentFilter !== '') {
      setIsCash('false');
      updatedUnits = updatedUnits.filter((unit) => unit.quarterInstallment && parseInt(unit.quarterInstallment.replace(/,/g, ''), 10) <= quarterInstallmentFilter);
      anyFiltersApplied = true;
    }
    if (isCash !== '') {
      if (isCash === 'true') {
        updatedUnits = updatedUnits.filter((unit) => unit.paymentPlan === 'Cash');
      } else {
        updatedUnits = updatedUnits.filter((unit) => unit.paymentPlan === 'Installments');
      }
      anyFiltersApplied = true;
    }
    if (forRentFilter === true) {
      updatedUnits = updatedUnits.filter((unit) => unit.forRent === true);
      anyFiltersApplied = true;
    } else {
      updatedUnits = updatedUnits.filter((unit) => unit.forRent === false);
      anyFiltersApplied = true;
    }
    if (searchingInput !== 'Any') {
      if(isSelectedLanguageEnglish){
        updatedUnits = updatedUnits.filter((unit) => unit.description.toLowerCase().includes(searchingInput.toLowerCase()));
      } else {
        updatedUnits = updatedUnits.filter((unit) => unit.arabicDescription.toLowerCase().includes(searchingInput.toLowerCase()));
      }
      anyFiltersApplied = true;
    }
    setFilteredUnits(updatedUnits);
    setIsFiltering(anyFiltersApplied)
  }, [units, locationFilter, bedroomsFilter, budgetFilter, propertyTypeFilter, zoneFilter, forRentFilter, downPaymentFilter, quarterInstallmentFilter, installmentsYearsFilter, isCash, searchingInput, isSelectedLanguageEnglish]);

  const resetFilters = () => {
    setLocationFilter('Any');
    setBedroomsFilter('Any');
    setBudgetFilter('');
    setDownPaymentFilter('');
    setQuarterInstallmentFilter('')
    setInstallmentsYearsFilter('');
    setIsCash('');
    setZoneFilter('Any');
    setPropertyTypeFilter('Any');
    setForRentFilter(false);
    setFilteredUnits([]);
    setIsFiltering(false);
  }

  const EnglishLocationsOptions = [
    { value: 'Any', label: 'Any' },
    { value: 'Cairo East', label: 'Cairo East' },
    { value: 'Cairo West', label: 'Cairo West' },
    { value: 'Old Cairo', label: 'Old Cairo' },
    { value: 'North Coast', label: 'North Coast' },
    { value: 'Red Sea', label: 'Red Sea' },
    { value: 'UAE_Dubai', label: 'UAE_Dubai' },
  ];
  const ArabicLocationsOptions = [
    { value: 'الكل', label: 'الكل' },
    { value: 'شرق القاهرة', label: 'شرق القاهرة' },
    { value: 'غرب القاهرة', label: 'غرب القاهرة' },
    { value: 'القاهرة القديمة', label: 'القاهرة القديمة' },
    { value: 'الساحل الشمالى', label: 'الساحل الشمالى' },
    { value: 'البحر الاحمر', label: 'البحر الاحمر' },
    { value: 'الأمارات دبى', label: 'الأمارات دبى' },
  ];
  const locationOptions = isSelectedLanguageEnglish ? EnglishLocationsOptions : ArabicLocationsOptions;
  const bedroomOptions = [
    { value: 'Any', },
    { value: '1', },
    { value: '2', },
    { value: '3', },
    { value: '4', },
    { value: '5+', },
  ];
  const EnglishPropertyTypeOptions = [
    { value: 'Any', label: 'Any' },
    { value: 'Studio', label: 'Studio' },
    { value: 'Apartment', label: 'Apartment' },
    { value: 'Apartment with garden', label: 'Apartment with garden' },
    { value: 'Duplex', label: 'Duplex' },
    { value: 'Villa', label: 'Villa' },
    { value: 'Twin House', label: 'Twin House' },
    { value: 'Town House', label: 'Town House' },
    { value: 'Pent House', label: 'Pent House' },
    { value: 'Stand Alone', label: 'Stand Alone' },
    { value: 'Sky Villa', label: 'Sky Villa' },
    { value: 'Sky Loft', label: 'Sky Loft' },
    { value: 'Loft', label: 'Loft' },
    { value: 'Lake House', label: 'Lake House' },
    { value: 'Mansion', label: 'Mansion' },
    { value: 'Cabin', label: 'Cabin' },
    { value: 'Condo', label: 'Condo' },
    { value: 'Quadro', label: 'Quadro' },
    { value: 'Food & Beverages', label: 'Food & Beverages' },
    { value: 'Villa Apartment', label: 'Villa Apartment' },
    { value: 'Chalet', label: 'Chalet' },
    { value: 'Office', label: 'Office' },
    { value: 'Clinic', label: 'Clinic' },
    { value: 'Retail', label: 'Retail' },
  ];
  const ArabicPropertyTypeOptions = [
    { value: 'Any', label: 'الكل' },
    { value: 'Studio', label: 'استوديو' },
    { value: 'Apartment', label: 'شقة' },
    { value: 'Apartment with garden', label: 'شقة بحديقة' },
    { value: 'Duplex', label: 'دوبلكس' },
    { value: 'Villa', label: 'فيلا' },
    { value: 'Twin House', label: 'توين هاوس' },
    { value: 'Town House', label: 'تاون هاوس' },
    { value: 'Pent House', label: 'بينت هاوس' },
    { value: 'Stand Alone', label: 'فيلا منفصلة' },
    { value: 'Sky Villa', label: 'سكاى فيلا' },
    { value: 'Sky Loft', label: 'سكاى لوفت' },
    { value: 'Loft', label: 'لوفت' },
    { value: 'Lake House', label: 'Lake House' },
    { value: 'Mansion', label: 'Mansion' },
    { value: 'Cabin', label: 'كابينه' },
    { value: 'Condo', label: 'فيلا كوندو' },
    { value: 'Quadro', label: 'فيلا كوادرو' },
    { value: 'Food & Beverages', label: 'مطعم' },
    { value: 'Villa Apartment', label: 'شقة فيلا' },
    { value: 'Chalet', label: 'شالية' },
    { value: 'Office', label: 'مكتب' },
    { value: 'Clinic', label: 'عيادة' },
    { value: 'Retail', label: 'تجارى' },
  ];
  const propertyTypeOptions = isSelectedLanguageEnglish ? EnglishPropertyTypeOptions : ArabicPropertyTypeOptions;

  const isCashOptions = [
    { value: '', label: 'Any' },
    { value: 'true', label: 'Cash' },
    { value: 'false', label: 'Installments' },
  ]
  const arabicIsCashOptions = [
    { value: '', label: 'الكل' },
    { value: 'true', label: 'كاش' },
    { value: 'false', label: 'تقسيط' },
  ]
  const paymentPlanOptions = isSelectedLanguageEnglish ? isCashOptions : arabicIsCashOptions;

  return (
    <div>
      <SEO
        title={'Units | Nubaq'}
        description={'Find your dream home with Nubaq comprehensive real estate services. Explore apartments, villas, and more tailored to your needs.'}
        keywords={'real estate, buy, house, home, apartments, villas, property, properties, sell, finish, rent, duplex, twin house, chalet, north coast, sahel, gouna, sokhna, cairo, new capital, zayed'}
        url={'https://www.nubaq.com/units'}
        image={'https://www.nubaq.com/assets/Icon.png'}
      />
      <MainLayout>
        <div className='flex items-center justify-center w-full px-6 md:px-12 pt-36'>
          <div className='flex flex-col gap-3 w-full items-center justify-center bg-white shadow border-2 border-green-500 rounded-lg p-2'>
            <div className={`flex flex-wrap w-full ${!isSelectedLanguageEnglish && 'flex-row-reverse'} items-center justify-center gap-2`}>
              <div className={`flex items-center justify-between border-2 border-gray-400 rounded-md w-full ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                <label className={`text-center text-sm p-2 w-1/3 ${isSelectedLanguageEnglish ? 'border-r-2' : 'border-l-2'} border-gray-400`}>{isSelectedLanguageEnglish ? 'Location' : 'الموقع'}</label>
                <Select className='focus:outline-none text-center w-2/3 bg-white h-full p-2 rounded-md' value={locationFilter} onChange={(e) => { setLocationFilter(e.target.value); setZoneFilter('Any'); }}>
                  {locationOptions.map((option, index) => (
                    <option value={option.value} key={index}>{option.label}</option>
                  ))}
                </Select>
              </div>
              {
                (locationFilter !== 'Any' && locationFilter !== 'UAE_Dubai' && locationFilter !== 'الأمارات دبى')
                && (
                  <div className={`flex items-center justify-between border-2 border-gray-400 rounded-md w-full ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                    <label className={`text-center text-sm p-2 w-1/3 ${isSelectedLanguageEnglish ? 'border-r-2' : 'border-l-2'}  border-gray-400`}>{isSelectedLanguageEnglish ? 'Zone' : 'المنطقة'}</label>
                    <Select className='focus:outline-none text-center w-2/3 bg-white p-2 h-full rounded-md' value={zoneFilter} onChange={(e) => setZoneFilter(e.target.value)}>
                      {isSelectedLanguageEnglish && getZone(locationFilter).map((option, index) => (
                        <option value={option} key={index}>{option}</option>
                      ))}
                      {!isSelectedLanguageEnglish && getArabicZone(locationFilter).map((option, index) => (
                        <option value={option} key={index}>{option}</option>
                      ))}
                    </Select>
                  </div>
                )
              }
              {
                !forRent && (
                  <div className={`flex items-center justify-between border-2 border-gray-400 rounded-md w-full ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                    <label className={`text-center text-sm p-2 w-1/3 ${isSelectedLanguageEnglish ? 'border-r-2' : 'border-l-2'}  border-gray-400`}>{isSelectedLanguageEnglish ? 'Payment Plan' : 'نظام السداد'}</label>
                    <Select className='focus:outline-none h-full w-2/3 bg-white text-center p-2 rounded-md' value={isCash}
                      onChange={(e) => {
                        setIsCash(e.target.value);
                        setBudgetFilter('');
                        setDownPaymentFilter('');
                        setQuarterInstallmentFilter('')
                        setInstallmentsYearsFilter('');
                      }}>
                      {paymentPlanOptions.map((option, index) => (
                        <option value={option.value} key={index}>{option.label}</option>
                      ))}
                    </Select>
                  </div>
                )
              }
              {
                isCash === 'true' && (
                  <div className={`flex items-center justify-between border-2 border-gray-400 rounded-md w-full ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                    <label className={`text-center text-sm p-2 w-1/3 ${isSelectedLanguageEnglish ? 'border-r-2' : 'border-l-2'}  border-gray-400`}>{isSelectedLanguageEnglish ? 'Budget' : 'الميزانية'}</label>
                    <input
                      type='text'
                      value={budgetFilter}
                      placeholder={isSelectedLanguageEnglish ? 'Budget...' : '...الميزانيه'}
                      className='px-4 py-2 h-full focus:outline-none text-center rounded-md w-2/3'
                      onChange={(e) =>{ e.preventDefault(); setBudgetFilter(e.target.value);}}
                      style={{ appearance: 'textfield' }}
                    />
                  </div>
                )
              }
              {
                isCash === 'false' && (
                  <div className={`flex items-center justify-between border-2 border-gray-400 rounded-md w-full ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                    <label className={`text-center text-sm p-2 w-1/3 ${isSelectedLanguageEnglish ? 'border-r-2' : 'border-l-2'}  border-gray-400`}>{isSelectedLanguageEnglish ? 'Down Payment' : 'المقدم'}</label>
                    <input
                      type='text'
                      value={downPaymentFilter}
                      placeholder={isSelectedLanguageEnglish ? 'Down Payment...' : '...المقدم'}
                      className='px-4 py-2 h-full focus:outline-none text-center rounded-md w-2/3'
                      onChange={(e) =>{ e.preventDefault(); setDownPaymentFilter(e.target.value);}}
                      style={{ appearance: 'textfield' }}
                    />
                  </div>
                )
              }
              {
                isCash === 'false' && (
                  <div className={`flex items-center justify-between border-2 border-gray-400 rounded-md w-full ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                    <label className={`text-center text-sm p-2 w-1/3 ${isSelectedLanguageEnglish ? 'border-r-2' : 'border-l-2'}  border-gray-400`}>{isSelectedLanguageEnglish ? 'Quarter Installment' : 'القسط الربع سنوى'}</label>
                    <input
                      type='text'
                      value={quarterInstallmentFilter}
                      placeholder={isSelectedLanguageEnglish ? 'Quarter Installment...' : '...القسط الربع سنوى'}
                      className='px-4 py-2 h-full focus:outline-none text-center rounded-md w-2/3'
                      onChange={(e) =>{ e.preventDefault(); setQuarterInstallmentFilter(e.target.value);}}
                      style={{ appearance: 'textfield' }}
                    />
                  </div>
                )
              }
              {
                isCash === 'false' && (
                  <div className={`flex items-center justify-between border-2 border-gray-400 rounded-md w-full ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                    <label className={`text-center text-sm p-2 w-1/3 ${isSelectedLanguageEnglish ? 'border-r-2' : 'border-l-2'}  border-gray-400`}>{isSelectedLanguageEnglish ? 'Installments Years' : 'سنوات الاقساط'}</label>
                    <input
                      type='number'
                      value={installmentsYearsFilter}
                      placeholder={isSelectedLanguageEnglish ? 'Installments Years...' : '...سنوات الاقساط'}
                      className='px-4 py-2 h-full focus:outline-none text-center rounded-md w-2/3'
                      onChange={(e) =>{ e.preventDefault(); setInstallmentsYearsFilter(e.target.value);}}
                      style={{ appearance: 'textfield' }}
                    />
                  </div>
                )
              }
              <div className={`flex items-center justify-between border-2 border-gray-400 rounded-md w-full ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                <label className={`text-center text-sm p-2 w-1/3 ${isSelectedLanguageEnglish ? 'border-r-2' : 'border-l-2'}  border-gray-400`}>{isSelectedLanguageEnglish ? 'Bedroom' : 'الغرف'}</label>
                <Select className='focus:outline-none h-full w-2/3 text-center bg-white p-2 rounded-md' value={bedroomsFilter} onChange={(e) => setBedroomsFilter(e.target.value)}>
                  {bedroomOptions.map((option, index) => (
                    <option value={option.value} key={index}>{option.value}</option>
                  ))}
                </Select>
              </div>
              <div className={`flex items-center justify-between border-2 border-gray-400 rounded-md w-full ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                <label className={`text-center text-sm p-2 w-1/3 ${isSelectedLanguageEnglish ? 'border-r-2' : 'border-l-2'}  border-gray-400`}>{isSelectedLanguageEnglish ? 'Property Type' : 'نوع الوحدة'}</label>
                <Select className='focus:outline-none h-full w-2/3 text-center bg-white p-2 rounded-md' value={propertyTypeFilter} onChange={(e) => setPropertyTypeFilter(e.target.value)}>
                  {propertyTypeOptions.map((option, index) => (
                    <option value={option.value} key={index}>{option.label}</option>
                  ))}
                </Select>
              </div>
            </div>
            <div className='flex gap-2'>
              <div className='flex flex-col gap-1 mx-2'>
                <button onClick={() => setForRentFilter(false)} className={`w-full whitespace-nowrap px-2 transition-all duration-500 rounded-lg hover:bg-green-500 hover:text-white border-2 border-green-500 ${forRentFilter === false ? 'bg-green-500 text-white' : 'bg-white text-black'}`}>{isSelectedLanguageEnglish ? 'For Sale' : 'للبيع'}</button>
                <button onClick={() => setForRentFilter(true)} className={`w-full whitespace-nowrap px-2 transition-all duration-500 rounded-lg hover:bg-green-500 hover:text-white border-2 border-green-500 ${forRentFilter === true ? 'bg-green-500 text-white' : 'bg-white text-black'}`}>{isSelectedLanguageEnglish ? 'For Rent' : 'للإيجار'}</button>
              </div>
              <button onClick={() => resetFilters()} className='bg-green-500 hover:bg-green-400 transition-all duration-500 w-full h-full  text-white rounded-2xl py-4 px-2'>{isSelectedLanguageEnglish ? 'reset filters' : 'اعادة التصنيفات'}</button>
            </div>
          </div>
        </div>
        {isFiltering ? <FilteredUnitsSection units={filteredUnits} /> : <AllUnitsSection units={units} />}
      </MainLayout>
    </div>
  )
}

export default UnitsPage
