import React, { useContext, useEffect, useState } from 'react';
import UnitComponent from '../../../components/UnitComponent';
import { AppContext } from '../../../AppContext';
import Select from '@mui/material/Select';
import { FormControl, InputLabel, MenuItem } from '@mui/material';

const AllUnitsSection = ({ units }) => {

    const { isSelectedLanguageEnglish } = useContext(AppContext);
    const [allUnits, setAllUnits] = useState([...units]);
    const [sort, setSort] = useState('Default');

    const EnglishSortOptions = [
        { value: 'Default', label: 'Default' },
        { value: 'Price: low to high', label: 'Price: low to high' },
        { value: 'Price: high to low', label: 'Price: high to low' },
        { value: 'Area: small to large', label: 'Area: small to large' },
        { value: 'Area: large to small', label: 'Area: large to small' },
    ];

    const ArabicSortOptions = [
        { value: 'Default', label: 'Default' },
        { value: 'Price: low to high', label: 'السعر: الأقل الى الأعلى' },
        { value: 'Price: high to low', label: 'السعر: الأعلى الى الأقل' },
        { value: 'Area: small to large', label: 'المساحه: الأقل الى الأعلى' },
        { value: 'Area: large to small', label: 'المساحه: الأعلى الى الأقل' },
    ];

    useEffect(() => {
        let tempUnits = [...units]; // Copy original units array

        if (sort === 'Price: low to high') {
            tempUnits.sort((a, b) =>
                parseInt(a.price.replace(/,/g, ''), 10) - parseInt(b.price.replace(/,/g, ''), 10)
            );
        } else if (sort === 'Price: high to low') {
            tempUnits.sort((a, b) =>
                parseInt(b.price.replace(/,/g, ''), 10) - parseInt(a.price.replace(/,/g, ''), 10)
            );
        } else if (sort === 'Area: small to large') {
            tempUnits.sort((a, b) => a.area - b.area);
        } else if (sort === 'Area: large to small') {
            tempUnits.sort((a, b) => b.area - a.area);
        }

        setAllUnits(tempUnits); // Update state with new sorted array
    }, [sort, units]);

    const handleSortChange = (event) => {
        setSort(event.target.value);
    };

    return (
        <div className='flex flex-col p-4 m-4 border shadow-md rounded-lg'>
            <div className='flex items-center justify-between'>
                <div className='flex justify-start gap-2 items-center p-2'>
                    <span className={`text-2xl lg:mx-8 font-semibold underline underline-offset-8 ${!isSelectedLanguageEnglish && 'text-right'}`}>{isSelectedLanguageEnglish ? 'All Units' : 'كل الوحدات'}</span>
                    <span className='text-neutral-600 text-md text-center'>{units.length} {isSelectedLanguageEnglish ? 'units found' : 'وحدة متاحة'}</span>
                </div>
                <div className="px-4">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            {isSelectedLanguageEnglish ? 'Sort' : 'الترتيب'}
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={sort}
                            label={isSelectedLanguageEnglish ? 'Sort' : 'الترتيب'}
                            onChange={handleSortChange}
                        >
                            {(isSelectedLanguageEnglish ? EnglishSortOptions : ArabicSortOptions).map((sortOption) => (
                                <MenuItem key={sortOption.value} value={sortOption.value}>
                                    {sortOption.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 m-4'>
                {
                    allUnits.map((unit) => {
                        return (
                            <UnitComponent unit={unit} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default AllUnitsSection
