import React, { useContext } from 'react';
import images from '../constants/Images';
import { MdMail } from 'react-icons/md';
import { BiPhone } from 'react-icons/bi';
import { FaLocationDot } from 'react-icons/fa6';
import SocialMedia from './SocialMedia';
import { AppContext } from '../AppContext';

const Footer = () => {
  const {isSelectedLanguageEnglish} = useContext(AppContext);
  return (
    <div className='bg-[#100c08] w-full grid grid-cols-1 lg:grid-cols-3 overflow-x-hidden'>

      {/* Left-aligned contact info */}
      <div className={`p-4 w-full lg:w-fit ${isSelectedLanguageEnglish ? 'text-left' : 'text-right'} text-white flex flex-col justify-center`}>
        <div className='p-2'>
          <h1 className='text-2xl'>{isSelectedLanguageEnglish ? 'Contacts' : 'التواصل'}</h1>
          <div className='p-1 text-[18px]'>
            <div>
              <div className={`flex justify-start items-center gap-2 ${!isSelectedLanguageEnglish && 'flex-row-reverse'}`}>
                <MdMail className='text-green-400' />
                <span>{isSelectedLanguageEnglish ? 'Email' : 'البريد الإلكترونى'}</span>
              </div>
              <button onClick={() => {window.location.href = "mailto:someone@example.com?subject=Your Subject&body=Your message here";}}>
                info@nubaq.com
              </button>
            </div>
            <div>
              <div className={`flex justify-start items-center gap-2 ${!isSelectedLanguageEnglish && 'flex-row-reverse'} mt-4`}>
                <BiPhone className='text-green-400' />
                <span>{isSelectedLanguageEnglish ? 'Mobile Number' : 'رقم الهاتف'}</span>
              </div>
              <a href='https://wa.me/message/26AJURO347ESI1'>+201145806539</a>
            </div>
            <div>
              <div className={`flex justify-start items-center gap-2 ${!isSelectedLanguageEnglish && 'flex-row-reverse'} mt-4`}>
                <FaLocationDot className='text-green-400' />
                <span>{isSelectedLanguageEnglish ? 'Location' : 'العنوان'}</span>
              </div>
              <div>
                <a
                  href='https://goo.gl/maps/hTukPNCG6QSRcxTd6'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-green-400 underline wrap'
                >
                  8B building, Diplomacieen <br />
                  (Second district) in Sheikh Zayed City
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Centered social media icons */}
      <div className='flex flex-col justify-center items-center w-full text-white p-4'>
        <SocialMedia />
      </div>

      {/* Right-aligned logo */}
      <div className='flex items-center justify-center p-4'>
        <img src={images.Logo} alt='logo' className='max-w-[300px]' />
      </div>
    </div>
  );
};

export default Footer;
