import React, { useContext, useEffect, useState } from 'react';
import UnitComponent from '../../../components/UnitComponent';
import { MdOutlineCancel } from 'react-icons/md';
import { AppContext } from '../../../AppContext';
import Select from '@mui/material/Select';
import { FormControl, InputLabel, MenuItem } from '@mui/material';

const FilteredUnitsSection = ({ units }) => {
    const { isSelectedLanguageEnglish } = useContext(AppContext);
    const [filteredUnits, setFilteredUnits] = useState([...units]);
    const [sort, setSort] = useState('Default');

    const EnglishSortOptions = [
        { value: 'Default', label: 'Default' },
        { value: 'Price: low to high', label: 'Price: low to high' },
        { value: 'Price: high to low', label: 'Price: high to low' },
        { value: 'Area: small to large', label: 'Area: small to large' },
        { value: 'Area: large to small', label: 'Area: large to small' },
    ];

    const ArabicSortOptions = [
        { value: 'Default', label: 'Default' },
        { value: 'Price: low to high', label: 'السعر: الأقل الى الأعلى' },
        { value: 'Price: high to low', label: 'السعر: الأعلى الى الأقل' },
        { value: 'Area: small to large', label: 'المساحه: الأقل الى الأعلى' },
        { value: 'Area: large to small', label: 'المساحه: الأعلى الى الأقل' },
    ];

    useEffect(() => {
        let tempUnits = [...units]; // Copy original units array

        if (sort === 'Price: low to high') {
            tempUnits.sort((a, b) =>
                parseInt(a.price.replace(/,/g, ''), 10) - parseInt(b.price.replace(/,/g, ''), 10)
            );
        } else if (sort === 'Price: high to low') {
            tempUnits.sort((a, b) =>
                parseInt(b.price.replace(/,/g, ''), 10) - parseInt(a.price.replace(/,/g, ''), 10)
            );
        } else if (sort === 'Area: small to large') {
            tempUnits.sort((a, b) => a.area - b.area);
        } else if (sort === 'Area: large to small') {
            tempUnits.sort((a, b) => b.area - a.area);
        }

        setFilteredUnits(tempUnits); // Update state with new sorted array
    }, [sort, units]); // Depend on sort and units to recompute when they change

    const handleSortChange = (event) => {
        setSort(event.target.value);
    };

    return (
        <div className="flex flex-col p-4 m-4 border shadow-md rounded-lg">
            <div className="flex items-center justify-between">
                <div className="flex justify-start items-center gap-2 p-2">
                    <span className={`text-2xl lg:mx-8 font-semibold underline underline-offset-8 ${!isSelectedLanguageEnglish && 'text-right'}`}>
                        {isSelectedLanguageEnglish ? 'Filtered Units' : 'الوحدات المصنفة'}
                    </span>
                    <span className="text-neutral-600 text-md text-center">
                        {filteredUnits.length} {isSelectedLanguageEnglish ? 'units found' : 'وحدة متاحة'}
                    </span>
                </div>
                <div className="px-4">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            {isSelectedLanguageEnglish ? 'Sort' : 'الترتيب'}
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={sort}
                            label={isSelectedLanguageEnglish ? 'Sort' : 'الترتيب'}
                            onChange={handleSortChange}
                        >
                            {(isSelectedLanguageEnglish ? EnglishSortOptions : ArabicSortOptions).map((sortOption) => (
                                <MenuItem key={sortOption.value} value={sortOption.value}>
                                    {sortOption.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>

            {filteredUnits.length === 0 ? (
                <div className="w-full h-full p-16 flex flex-col gap-2 text-gray-600 items-center justify-center">
                    <MdOutlineCancel className="text-[48px]" />
                    <label>{isSelectedLanguageEnglish ? 'No units match the filters' : 'لا يوجد وحدات مطابقة لهذه التصنيفات'}</label>
                </div>
            ) : (
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 m-4">
                    {filteredUnits.map((unit) => (
                        <UnitComponent key={unit.id} unit={unit} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default FilteredUnitsSection;
