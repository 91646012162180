import React, { useState, useEffect, useContext } from 'react';
import MainLayout from '../../components/MainLayout';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import UnitComponent from '../../components/UnitComponent';
import SEO from '../../components/SEO';
import { FaLocationDot } from 'react-icons/fa6';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import ReactMarkdown from 'react-markdown';

const ProjectPage = () => {
  const { getProjectById, getUnitsFromProject, loading: contextLoading, isSelectedLanguageEnglish } = useContext(AppContext);
  const { id } = useParams();

  const [project, setProject] = useState(null);
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [sort, setSort] = useState('Default');

  const EnglishSortOptions = [
    { value: 'Default', label: 'Default' },
    { value: 'Price: low to high', label: 'Price: low to high' },
    { value: 'Price: high to low', label: 'Price: high to low' },
    { value: 'Area: small to large', label: 'Area: small to large' },
    { value: 'Area: large to small', label: 'Area: large to small' },
  ];

  const ArabicSortOptions = [
    { value: 'Default', label: 'Default' },
    { value: 'Price: low to high', label: 'السعر: الأقل الى الأعلى' },
    { value: 'Price: high to low', label: 'السعر: الأعلى الى الأقل' },
    { value: 'Area: small to large', label: 'المساحه: الأقل الى الأعلى' },
    { value: 'Area: large to small', label: 'المساحه: الأعلى الى الأقل' },
  ];

  useEffect(() => {
    let tempUnits = [...units]; // Copy original units array

    if (sort === 'Price: low to high') {
      tempUnits.sort((a, b) =>
        parseInt(a.price.replace(/,/g, ''), 10) - parseInt(b.price.replace(/,/g, ''), 10)
      );
    } else if (sort === 'Price: high to low') {
      tempUnits.sort((a, b) =>
        parseInt(b.price.replace(/,/g, ''), 10) - parseInt(a.price.replace(/,/g, ''), 10)
      );
    } else if (sort === 'Area: small to large') {
      tempUnits.sort((a, b) => a.area - b.area);
    } else if (sort === 'Area: large to small') {
      tempUnits.sort((a, b) => b.area - a.area);
    }

    setUnits(tempUnits); // Update state with new sorted array
  }, [sort, units]); // Depend on sort and units to recompute when they change

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  useEffect(() => {
    const fetchDeveloper = async () => {
      if (contextLoading) return; // Wait for context to finish loading
      try {
        const projectsData = await getProjectById(id);
        if (!projectsData) {
          throw new Error("Error fetching project data")
        }
        const unitsData = await getUnitsFromProject(projectsData.name);
        if (!unitsData) {
          throw new Error("Error fetching units data")
        }
        setProject(projectsData);
        setUnits(unitsData);
      } catch (err) {
        setError(err.message || 'Failed to fetch project data.');
      } finally {
        setLoading(false);
      }
    };

    fetchDeveloper();
  }, [getProjectById, getUnitsFromProject, id, contextLoading]);

  if (contextLoading || loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <SEO
        title={`${project.name} | Nubaq`}
        description={'Find your dream home with Nubaq comprehensive real estate services. Explore apartments, villas, and more tailored to your needs.'}
        keywords={'real estate, buy, house, home, apartments, villas, property, properties, sell, finish, rent, duplex, twin house, chalet, north coast, sahel, gouna, sokhna, cairo, new capital, zayed'}
        url={'https://www.nubaq.com/projects/:id'}
        image={'https://www.nubaq.com/assets/Icon.png'}
      />
      <MainLayout>
        <div className='pt-40 w-full flex flex-col gap-4'>
          <div className='w-full flex flex-col items-center justify-center'>
            <div className='flex flex-col gap-4 w-full justify-center items-center px-4'>
              <h1 className='font-semibold text-center text-[20px] md:text-[26px] lg:text-[32px]'>{isSelectedLanguageEnglish ? project.name : project.arabicName}</h1>
              <img className='w-2/3 max-w-[400px]' src={project.logoLink} alt='project Logo' />
            </div>
            <div className='p-4 lg:px-8 text-center lg:text-left sm:text-[10px] md:text-[20px] lg:text-[24px]'>
              <ReactMarkdown >{isSelectedLanguageEnglish ? project.description : project.arabicDescription}</ReactMarkdown>
            </div>
            <div className={`flex ${!isSelectedLanguageEnglish && 'flex-row-reverse text-right'} items-center gap-4 py-8 px-16`}>
              <FaLocationDot className='min-w-16 text-nubaqgreen text-3xl' />
              <span className='whitespace-break-spaces'>{isSelectedLanguageEnglish ? project.address : project.arabicAddress}</span>
            </div>
          </div>
          <div className='m-2 border-2 border-nubaqgreen rounded-xl p-4 flex flex-col'>
            <div className='flex items-center justify-between'>
              <div className={`text-[24px] font-semibold text-nubaqgreen ${!isSelectedLanguageEnglish && 'text-right'}`}>{isSelectedLanguageEnglish ? 'Units' : 'الوحدات'}</div>
              <div className="px-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {isSelectedLanguageEnglish ? 'Sort' : 'الترتيب'}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sort}
                    label={isSelectedLanguageEnglish ? 'Sort' : 'الترتيب'}
                    onChange={handleSortChange}
                  >
                    {(isSelectedLanguageEnglish ? EnglishSortOptions : ArabicSortOptions).map((sortOption) => (
                      <MenuItem key={sortOption.value} value={sortOption.value}>
                        {sortOption.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className='p-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
              {units.map((unit) => (
                <UnitComponent unit={unit} />
              ))}
            </div>
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default ProjectPage;
